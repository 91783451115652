<template>
  <div class="container">
    <el-row>
      <content-title :nav="nav"></content-title>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-descriptions title="用户信息" :column="1">
          <el-descriptions-item
            :labelStyle="{ display: 'flex', alignItems: 'center' }"
            label="头像"
          >
            <el-image
              v-if="userInfo.avatar"
              style="width: 5rem; border-radius: 0.5rem"
              :src="userInfo.avatar"
            ></el-image>
          </el-descriptions-item>
          <el-descriptions-item label="昵称">
            <span>{{ userInfo.nick_name }}</span>
            <span style="color: #409eff; margin-left: 15px" v-if="IsRecommed"
              >团长</span>
              <span style="color: #409eff; margin-left: 15px" v-if="type == 'coach'"
              >教练</span>
          </el-descriptions-item>
          <el-descriptions-item label="手机号">{{
            userInfo.mobile
          }}</el-descriptions-item>
          <el-descriptions-item label="卡券数量">{{
            couptotal + cardList.length
          }}</el-descriptions-item>
          <el-descriptions-item label="注册时间">{{
            userInfo.create_time
          }}</el-descriptions-item>
          <el-descriptions-item label="注册来源">{{
           getRegisterPlatform(userInfo.register_platform )
          }}</el-descriptions-item>
          <el-descriptions-item label="最近一次登陆时间">{{
            userInfo.update_time
          }}</el-descriptions-item>
          <el-descriptions-item label="社区信息">
            <div class="flex">
              <el-tag v-if="userInfo.interest_group">{{userInfo.interest_group}}</el-tag>  
               <el-button v-if="userInfo.community_id" type="primary" size="small" @click="showCommunity">查看社区信息</el-button>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="家庭成员信息">
            <p v-if="childrenData.length == 0">暂无信息</p>
            <el-table
              v-else
              ref="multipleTable"
              style="width: 448px"
              :data="childrenData"
              tooltip-effect="dark"
            >
              <!-- <el-table-column prop="name" label="用户ID"> </el-table-column> -->
              <el-table-column prop="name" label="姓名"> </el-table-column>
              <el-table-column
                prop="type"
                label="身份"
              ></el-table-column>
              <el-table-column v-slot="{row}" label="生日">
                {{row.birth.split(' ')[0]}}
              </el-table-column>
            </el-table>
          </el-descriptions-item>
        </el-descriptions>
      </el-col>

      <el-col :span="12" v-if="cardInfo.id">
        <el-descriptions title="会员卡信息" :column="2">
          <el-descriptions-item label="会员卡类型">{{
            cardInfo.type
          }}</el-descriptions-item>
          <el-descriptions-item label="权益点数"
            >{{ cardInfo.map.point }}
            <span style="color: #409eff" @click="exchange(cardInfo)">充值</span>
          </el-descriptions-item>
          <el-descriptions-item label="开卡时间">{{
            cardInfo.create_time
          }}</el-descriptions-item>
          <el-descriptions-item label="有效期">{{
            cardInfo.end_time
          }}</el-descriptions-item>
        </el-descriptions>
      </el-col>
      <el-col :span="12" v-else>
        <el-descriptions title="暂无会员卡信息" :column="1"> </el-descriptions>
      </el-col>
    </el-row>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane name="first" label="订单记录">
        <el-row>
          <label for="">订单号</label>
          <el-input v-model="flowing_no"></el-input>
          <label for="">订单类型</label>
          <el-select v-model="rcharge_type">
            <el-option
              v-for="(t, index) in rcharge_types"
              :key="index"
              :label="t.label"
              :value="t.value"
            ></el-option>
          </el-select>
          <label for="">支付状态</label>
          <el-select v-model="status_group" value-key="id" @change="getStatus">
            <el-option
              v-for="status in status_types"
              :key="status.id"
              :label="status.label"
              :value="status.status"
            >
            </el-option>
          </el-select>
          <el-button type="primary" @click.enter="search()">搜索</el-button>
        </el-row>
        <el-table :data="list" v-loading="is_loading">
          <!-- <el-table-column label="ID" prop="id"></el-table-column> -->
          <el-table-column label="订单号" prop="flowing_no"></el-table-column>
          <el-table-column label="数量" prop="course_num"></el-table-column>
          <el-table-column label="支付金额" prop="amount"></el-table-column>
          <el-table-column label="订单类型" width="200px">
            <template slot-scope="{ row }">
              <div>
                {{ getorderType(row.rcharge_type) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="备注"
            prop="rcharge_abstract"
          ></el-table-column>
          <el-table-column
            label="创建时间"
            prop="rcharge_time"
            width="200px"
          ></el-table-column>
          <el-table-column label="支付方式" prop="pay_type"></el-table-column>
          <el-table-column label="状态" prop="status">
            <template slot-scope="scope" v-if="scope.row.id">
              <div>
                <!-- <span v-if="scope.row.status == 0">已失效</span> -->
                <!-- <span v-if='scope.row.status == 1'>未完成</span>  -->
                <span :style="{ color: getOrderStatus(scope.row).color }">{{
                  getOrderStatus(scope.row).name
                }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" prop="status">
            <template slot-scope="{ row }">
              <el-button
                type="success"
                v-if="canChangeOrder(row)"
                @click="openChangeVisible(row)"
                size="small"
                >申请换课</el-button
              >
              <el-button
                type="success"
                v-if="row.reverse_status == 2"
                @click="cancelSaleAfter(row)"
                size="small"
                >取消换课</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :total="total"
          :current-page="currentPage"
          @current-change="currentPageChange"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane name="second" label="权益点明细">
        <el-table :data="list" v-loading="is_loading">
          <el-table-column label="订单号" prop="">
            <template v-slot="{ row }">
              <p>{{ row.flowing_no || "—" }}</p>
            </template>
          </el-table-column>
          <el-table-column
            label="商品名称"
            prop="map.masterSetPriceEntity.title"
          ></el-table-column>
          <el-table-column label="变动数量" prop="point"></el-table-column>
          <el-table-column label="变动类型" prop="type"></el-table-column>
          <el-table-column
            label="变动时间"
            prop="create_time"
          ></el-table-column>
          <el-table-column label="操作人" prop="">
            <template v-slot="{ row }">
              <el-tag type="success" v-if="row.admin_id">{{
                row.admin_name
              }}</el-tag>
              <el-tag v-else>海马后台</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="reason"></el-table-column>
        </el-table>
        <el-pagination
          :total="total"
          :current-page="currentPage"
          @current-change="currentPageChange"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane name="third" label="已兑课程">
        <el-button type="primary" @click="chooseCourse">兑换课程</el-button>
        <el-table :data="list" v-loading="is_loading">
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column label="商品名称">
            <template v-slot="{ row }">
              <p v-if="row.type == 'venue'">
                {{ row.map.serverEntity.name }}
              </p>
              <p v-else>{{ row.map.masterSetPriceEntity.title }}</p>
            </template>
          </el-table-column>
          <el-table-column label="商品类目" prop="categories_child">
            <template v-slot="{ row }">
              <p>{{ row.categories_child || row.categories }}</p>
            </template>
          </el-table-column>
          <el-table-column label="商品价格">
            <template v-slot="{ row }">
              <p v-if="row.type == 'venue'">
                {{
                  row.map.serverEntity.price ||
                  row.map.serverEntity.discount_price
                }}
              </p>
              <p v-else>{{ row.map.masterSetPriceEntity.discount_amout }}</p>
            </template>
          </el-table-column>
          <el-table-column
            label="总课时数"
            prop="original_course_num"
          ></el-table-column>
          <el-table-column
            label="剩余课时数"
            prop="course_num"
          ></el-table-column>
          <el-table-column
            label="购买时间"
            prop="create_time"
          ></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <div class="flex">
                <el-button
                  type="primary"
                  @click="showAppotion('single', row)"
                  size="small"
                  >上课情况</el-button
                >
                <el-button
                  v-if="row.course_num > 0"
                  type="danger"
                  @click="completeCourse(row)"
                  size="small"
                  >结束课程</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :total="total"
          :current-page="currentPage"
          @current-change="currentPageChange"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane name="four" label="课程表">
        <el-button type="primary" @click="showAppotion('all')"
          >查看详情</el-button
        >
        <el-table :data="NoStartCourse">
          <el-table-column prop="title" label="课程名称"></el-table-column>
          <el-table-column prop="categories_child" label="类别">
            <template v-slot="{ row }">
              <p v-if="row.master_type == 'venue'">场馆服务</p>
              <p v-else-if="row.master_type == 'need_bond'">教练需求</p>
              <p v-else>机构课程</p>
            </template>
          </el-table-column>
          <el-table-column prop="mechanism_name" label="机构">
            <template v-slot="{ row }">
              <p>
                {{
                  row.mechanism_name || row.map.mechanismEntity.mechanism_name
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="start_time" label="上课时间" width="200">
          </el-table-column>
          <el-table-column prop="end_time" label="结束时间" width="200">
          </el-table-column>
          <!-- <el-table-column prop="end_time" label="课节数"></el-table-column> -->
          <el-table-column label="状态">
            <template> 已预约 </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :total="ctotal"
          :current-page="currentPage"
          @current-change="currentPageChange"
        ></el-pagination>
      </el-tab-pane>

      <!-- <el-tab-pane name="four2" label="联系记录">
        <el-form :inline="true" :model="Searchlist" class="demo-form-inline">
          <el-form-item label="手机号">
            <el-input v-model="Searchlist.value1"></el-input>
          </el-form-item>
          <el-form-item label="所属客服">
            <el-input v-model="Searchlist.value2"></el-input>
          </el-form-item>
          <el-form-item label="日期">
            <el-date-picker
              v-model="Searchlist.value3"
              value-format="yyyy-MM-dd hh:mm:ss"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button @click="searchclick" type="primary">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="dialogVisible1 = true"
              >新增</el-button
            >
          </el-form-item>
        </el-form>
        <el-table :data="tableData">
          <el-table-column label="昵称" prop="name"></el-table-column>
          <el-table-column prop="title" label="手机号"></el-table-column>
          <el-table-column prop="categories_child" label="沟通时间">
          </el-table-column>
          <el-table-column prop="mechanism_name" label="沟通时间">
          </el-table-column>
          <el-table-column prop="start_time" label="沟通方式">
          </el-table-column>
          <el-table-column prop="end_time" label="所属客服"> </el-table-column>
          <el-table-column label="沟通内容">
            <template>
              <samp style="color: #409eff" @click="dialogVisible = true"
                >查看内容</samp
              >
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          :total="total"
          :current-page="currentPage"
          @current-change="currentPageChange"
        ></el-pagination>
      </el-tab-pane> -->

      <!-- 联系记录点击窗口 -->
      <el-dialog :visible.sync="dialogVisible" width="400px" title="沟通内容">
        <p style="padding: 20px; border: 1px solid #dcdfe6"></p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
      </el-dialog>

      <!-- 新增记录点击窗口 -->
      <el-dialog
        :visible.sync="dialogVisible1"
        width="700px"
        title="新增联系记录"
      >
        <ul style="padding-left: 50px; padding-right: 40px">
          <li class="flex jc-b" style="padding-left: 28px">
            <p>
              <span style="color: #f56c6c">*</span>昵称
              <el-input></el-input>
            </p>
            <p>
              <span style="color: #f56c6c">*</span>手机号
              <el-input></el-input>
            </p>
          </li>
          <li class="flex jc-b" style="margin: 21px 0">
            <p>
              <span style="color: #f56c6c">*</span>沟通时间
              <el-input></el-input>
            </p>
            <p>
              <span style="color: #f56c6c">*</span>沟通方式
              <el-input></el-input>
            </p>
          </li>
          <li style="display: flex; justify-content: space-between">
            <p>
              <span style="color: #f56c6c">*</span>所属客服
              <el-input></el-input>
            </p>
            <p>
              <span style="color: #f56c6c">*</span>沟通内容
              <el-input></el-input>
            </p>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible1 = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible1 = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>

      <el-tab-pane name="five" label="卡券使用记录">
        <div class="flex">
          <el-select v-model="coupon_type">
            <el-option label="未使用" value="1"></el-option>
            <el-option label="已使用" value="3"></el-option>
            <el-option label="已过期" value="2"></el-option>
          </el-select>
          <el-button type="primary" @click="searchCoupon">查 询</el-button>
        </div>

        <el-table :data="tableData">
          <el-table-column label="类型" prop="type"></el-table-column>
          <el-table-column
            prop="create_time"
            label="领取时间"
          ></el-table-column>
          <el-table-column
            prop="overdue_time"
            label="有效期"
            v-if="coupon_status != 3"
          ></el-table-column>
          <el-table-column
            prop="use_time"
            label="使用时间"
            width="150"
            v-if="coupon_status == 3"
          >
          </el-table-column>
          <el-table-column label="使用状态">
            <template slot-scope="{ row }">
              <samp v-if="row.status == 3" style="color: #67c23a">已使用</samp>
              <samp v-if="row.status == 1" style="color: #409eff">未使用</samp>
              <samp v-if="row.status == 2" style="color: #f56c6c">已过期</samp>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :total="total"
          :current-page="currentPage"
          @current-change="currentPageChange"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane name="six" label="佣金明细">
        <el-table :data="tableData">
          <el-table-column label="被邀请人" prop="map.userinfo.nick_name">
            <template slot-scope="{ row }">
              {{ row.map.userinfo ? row.map.userinfo.nick_name : "用户" }}
            </template>
          </el-table-column>
          <el-table-column prop="type" label="佣金类型"></el-table-column>
          <el-table-column prop="cash" label="金额"> </el-table-column>
          <el-table-column prop="create_time" label="获取时间">
          </el-table-column>
        </el-table>
        <el-pagination
          :total="total"
          :current-page="currentPage"
          @current-change="currentPageChange"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane name="consultant" label="顾问计划">
        <plan-list :user_id="id" :type="1"></plan-list>
      </el-tab-pane>
    </el-tabs>

    <el-dialog title="卡券列表" :visible.sync="dialogTableVisible">
      <el-tabs v-model="activeName2" @tab-click="handleClick2">
        <el-tab-pane
          v-for="(item, index) in tabslist"
          :key="index"
          :label="item"
          :value="index"
        ></el-tab-pane>
      </el-tabs>
      <div v-if="activeName2 == 0">
        <el-table :data="cardList">
          <el-table-column
            prop="type"
            label="会员卡"
            width="150"
          ></el-table-column>
          <el-table-column label="是否可用" width="200">
            <template slot-scope="{ row }">
              {{ row.is_end ? "否" : "是" }}
            </template>
          </el-table-column>
          <el-table-column prop="end_time" label="过期时间"></el-table-column>
          <el-table-column label="选择">
            <template slot-scope="{ row }">
              <el-button type="primary" @click="toCourse(row)">选择</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else>
        <el-table :data="Couponlist">
          <el-table-column
            prop="type"
            label="天赋课程券"
            width="150"
          ></el-table-column>
          <el-table-column label="是否可用" width="200">
            <template slot-scope="{ row }">
              {{ row.is_end ? "否" : "是" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="overdue_time"
            label="过期时间"
          ></el-table-column>
          <el-table-column label="选择">
            <template slot-scope="{ row }">
              <el-button type="primary" @click="couptoCourse(row)"
                >选择</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 课程表 -->
    <el-dialog title="课程列表" :visible.sync="appointShow" width="70%">
      <div style="margin-bottom: 10px">
        <el-select
          v-model="appstatus"
          value-key="status"
          @change="searchappoint"
        >
          <el-option
            v-for="status in appo_status"
            :key="status.id"
            :label="status.label"
            :value="status"
          >
          </el-option>
        </el-select>
        <el-button @click="searchappoint" type="primary">搜索</el-button>
        <el-button @click.enter="toCheck" type="info" style="float: right"
          >去审核</el-button
        >
      </div>
      <el-table
        :data="appointCourse"
        v-loading="appointmenting"
        :default-sort="{ prop: 'start_time', order: 'descending' }"
      >
        <el-table-column label="序号">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="title" label="课程名称"></el-table-column>
        <el-table-column
          prop="start_time"
          sortable
          label="上课时间"
          width="200"
        >
        </el-table-column>
        <!-- <el-table-column prop="end_time" label="课节数"></el-table-column> -->
        <el-table-column label="状态">
          <template>
            {{ appo_status[appstatus.id - 1].label }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <!-- <el-button type="warning" slot="reference" @click="adjustCourse"
            v-if="appstatus.id==1 && row.checkin_status == 1" size="small"
              >课程调整</el-button> -->

            <el-popconfirm
              v-if="row.checkin_status == 1 && appstatus.id == 1"
              title="确定核销该课程吗？"
              @confirm="toHexiao(row)"
            >
              <el-button type="primary" slot="reference" size="small"
                >核销</el-button
              >
            </el-popconfirm>
            <el-button
              type="info"
              v-if="appstatus.id != 2"
              @click="updateTime(row)"
              size="small"
              >修改时间</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <paging-fy
        @currentPageChange="appointCoursePage"
        :currentPage="a_page"
        :total="a_total"
      ></paging-fy>
    </el-dialog>

    <!-- 课程调整 -->
    <el-dialog title="核销调整" :visible.sync="adjustShow">
      <el-form>
        <el-form-item label="结算金额" required>
          <el-input
            v-model="adjust.price"
            placeholder="结算金额"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="调整原因" required>
          <el-input
            type="textarea"
            :rows="5"
            placeholder="调整原因"
            v-model="adjust.resone"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="adjustSubmit"
            :disabled="adjusting"
            :key="adjusting"
            >提 交</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 修改上课时间 -->
    <el-dialog title="修改时间" :visible.sync="timeshow" width="400px">
      <el-form style="margin: 0 auto">
        <el-form-item label="上课日期">
          <el-date-picker
            v-model="date"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-time-picker
            v-model="value1"
            :picker-options="{
              selectableRange: '07:00:00 - 23:30:00',
            }"
            placeholder="课程开始时间"
            value-format="HH:mm:ss"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-time-picker
            v-model="value2"
            :picker-options="{
              selectableRange: value1 + ' - 23:59:00',
            }"
            placeholder="课程结束时间"
            value-format="HH:mm:ss"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="说明">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="remark"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="subTime">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--  -->
    <el-dialog title="申请换课" :visible.sync="changeVisible" width="40%">
      <el-form>
        <el-form-item label="换课原因" required>
          <el-input
            type="textarea"
            rows="4"
            v-model="change_reson"
            placeholder="请输入换课原因"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="changeVisible = false">取 消</el-button>
        <el-button type="primary" @click="applyChangeCourse">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="调整权益点" :visible.sync="pointShow" width="40%">
      <div>
        <el-form :inline="true" :model="changePoint" class="demo-form-inline">
          <el-form-item label="增加+">
            <el-input
              v-model="changePoint.addPoint"
              placeholder="增加点数"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="changePoint.addDesc"
              placeholder="备注"
            ></el-input>
          </el-form-item>

          <el-form-item label="减少-">
            <el-input
              v-model="changePoint.reducePoint"
              placeholder="减少点数"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="changePoint.reduceDesc"
              placeholder="备注"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="pointShow = false">取 消</el-button>
        <el-button type="primary" @click="pointChange">确 定</el-button>
      </span>
    </el-dialog>


    <el-dialog title="社区信息" :visible.sync="com_show" width="40%">
      <div>
        <el-form>
          <el-form-item label="社区">
           {{community.mechanism_name}}
          </el-form-item>

          <el-form-item label="楼号">
             {{userInfo.floor_no}}
          </el-form-item>
          <el-form-item label="房号">
            {{userInfo.room_no}}
          </el-form-item>
          
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="com_show = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { orderType, getorderType } from "@/utils/map/orderType.js";
import planList from "./components/planList.vue";
export default {
  components: { planList },
  data() {
    return {
      registerPlatform:[{
          value:'sport',
          name:'海马运动go小程序'
      },{
          value:'sport_coach_wx',
          name:'海马教练局小程序'
      },{
          value:'dy_sport',
          name:'海马运动抖音小程序'
      },{
          value:'android',
          name:'海马运动android_APP'
      },{
          value:'ios',
          name:'海马运动ios_APP'
      },{
          value:'android_coach',
          name:'海马教练局android_APP'
      },{
          value:'ios_coach',
          name:'海马教练局ios_APP'
      }],
      id: "",
      dialogVisible: false,
      dialogVisible1: false,
      adjust: {},
      adjustShow: false,
      adjusting: false,
      activeName2: 0,
      ctotal: 0,
      appointmenting: true,
      tabslist: ["会员卡", "课程券"],
      Searchlist: {
        value1: "",
        value2: "",
        value3: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 86400000;
        },
      },
      couptotal: 0,
      tableData: [], //模拟数据
      time: "",
      timeshow: false,
      nav: { firstNav: "用户管理", secondNav: "用户详情" },
      form: {},
      userInfo: {},
      activeName: "first",
      total: 0,
      list: [],
      cardInfo: {},
      flowing_no: null,
      rcharge_type: "",
      rcharge_time: [],
      currentPage: 1,
      is_loading: true,
      status: null,
      finished: null,
      status_group: null,
      status_types: [
        { id: 1, status: 0, label: "待付款" },
        { id: 2, status: 2, label: "已支付" },
        { id: 4, status: 3, label: "已退款" },
      ],
      dialogTableVisible: false,
      cardList: [],
      appointCourse: [],
      appointCourse1: [],
      childrenData: [],
      appointShow: false,
      curAppoint: {},
      appstatus: { id: 1, status: 2, label: "已预约" },
      appo_status: [
        { id: 1, status: 2, label: "已预约" },
        { id: 2, status: 3, label: "已完成" },
        { id: 3, status: 10, label: "已失效" },
      ],
      NoStartCourse: [],
      curCourse: {},
      appointShow1: false,
      appstatus1: { id: 1, status: 2, label: "已预约" },
      appo_status1: [
        { id: 1, status: 2, label: "已预约" },
        { id: 2, status: 3, label: "已完成" },
      ],
      coupon_type: "1",
      IsRecommed: false,
      start_time: "",
      end_time: "",
      date: "",
      value1: "",
      value2: "",
      coupon_status: "",
      a_page: 1,
      a_total: 0,
      changeVisible: false,
      change_reson: "",
      changeCourse: {},
      rcharge_types: orderType,
      remark:'',
      statusList: {
        0: { name: "待付款", color: "#fb3a13" },
        1: { name: "待付款", color: "#fb3a13" },
        2: { name: "已付款", color: "#00aa7f" },
        3: { name: "已退款", color: "#f0ad4e" },
        10: { name: "待退款", color: "#333" },
        8: { name: "已取消", color: "#999" },
        5: { name: "已取消", color: "#999" },
      },
      reverse_statusList: {
        1: { name: "退课中", color: "#f0ad4e" },
        2: { name: "换课中", color: "#f0ad4e" },
        3: { name: "已退课", color: "#FC7819" },
        4: { name: "拒绝退课", color: "#fb3a13" },
        5: { name: "已换课", color: "#FC7819" },
        6: { name: "拒绝换课", color: "#fb3a13" },
      },
      cur: {},
      openMode: "",
      pointShow: false,
      changePoint: {
        addPoint: "",
        addDesc: "",
        reducePoint: "",
        reduceDesc: "",
      },
      community:{},
      com_show :false
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getUserInfo()
  },
  methods: {
    getRegisterPlatform(v){
      for(let i = 0;i<this.registerPlatform.length;i++){
        if(this.registerPlatform[i].value === v){
          return this.registerPlatform[i].name
        }
      }
      return ''
        
    },
    showCommunity(){
          this.$axios({
        url: "user/mastermechanism/findById",
        params: {
          id:this.userInfo.community_id
        },
      }).then((res) => {
        this.community = res.data.data
        this.com_show = true
      });
    },
    exchange(row) {
      this.cur = row;
      this.pointShow = true;
    },
    pointChange() {
      let url = "";
      let num = 0;
      let reason = "";
      if (
        this.changePoint.addPoint == "" &&
        this.changePoint.reducePoint == ""
      ) {
        this.$message({
          message: "请确认增加或减少",
          type: "info",
        });
        return;
      }
      if (this.changePoint.addDesc == "" && this.changePoint.reduceDesc == "") {
        this.$message({
          message: "请填写权益点变动原因",
          type: "info",
        });
        return;
      }
      if (this.changePoint.addPoint != "" && this.changePoint.addPoint > 0) {
        url = "/user/userPoint/updateAddPoint";
        num = this.changePoint.addPoint;
        reason = this.changePoint.addDesc;
      } else if (
        this.changePoint.reducePoint != "" &&
        this.changePoint.reducePoint > 0
      ) {
        url = "/user/userPoint/updateSubPoint";
        num = this.changePoint.reducePoint;
        reason = this.changePoint.reduceDesc;
      } else {
        this.$message({
          message: "请输入正确的点数",
          type: "info",
        });
        return;
      }
      this.$axios({
        url,
        data: {
          id: this.cur.point_id,
          point: Number(num),
          reason,
          admin_id: JSON.parse(localStorage.getItem("managerInfo")).id,
          admin_name: JSON.parse(localStorage.getItem("managerInfo")).name,
        },
        method: "post",
      }).then((res) => {
        this.changePoint = {
          addPoint: "",
          addDesc: "",
          reducePoint: "",
          reduceDesc: "",
        };
        if (res.data.code == 0) {
          this.queryCardList();
          this.pointShow = false;
        }
        this.$message({
          message: res.data.message,
          type: "info",
        });
      });
    },
    getorderType(t) {
      return getorderType(t).label;
    },
    //是否可换课
    canChangeOrder(row) {
      let { status, reverse_status, rcharge_type } = row;
      if (
        status == 2 &&
        (reverse_status == 0 || reverse_status == 4 || reverse_status == 6)
      ) {
        if (rcharge_type == "study_card" && row.user_study_card_id) {
          return true;
        }
      }
      return false;
    },
    // 订单状态
    getOrderStatus(row) {
      let { status, reverse_status } = row;
      if (reverse_status != 0) {
        return this.reverse_statusList[reverse_status];
      } else {
        return this.statusList[status];
      }
    },
    openChangeVisible(row) {
      this.changeCourse = row;
      this.changeVisible = true;
    },
    // 取消换课
    cancelSaleAfter() {},
    //申请换课
    applyChangeCourse() {
      if (this.change_reson) {
        this.$axios({
          url: "/user/pay/afterSale",
          data: {
            flowing_no: this.changeCourse.flowing_no,
            reverse_status: 2,
            reason: this.change_reson,
          },
          method: "post",
        }).then((res) => {
          this.$message({ message: res.data.message });
          if (res.data.code == 0) {
            this.changeVisible = false;
            this.$router.push({ name: "Refundaudit" });
          }
        });
      } else {
        this.$message({ message: "请输入换课原因" });
      }
    },
    //课程调整
    adjustCourse(row) {
      this.curCourse = row;
      this.adjustShow = true;
    },
    adjustSubmit() {
      this.adjusting = true;
      this.$axios({
        url: "user/userRecommender/queryManagerListPage",
        data: {
          user_id: this.userInfo.user_id,
        },
        method: "post",
      }).then((res) => {
        this.adjusting = false;
        if (res.data.code == 0) {
          this.adjustShow = false;
          this.searchappoint();
          this.$message({ message: res.data.message });
        }
      });
    },
    UserIsRecommed() {
      this.$axios({
        url: "user/userRecommender/queryManagerListPage",
        params: {
          user_id: this.userInfo.user_id,
        },
      }).then((res) => {
        if (res.data.data.rows.length > 0) {
          this.IsRecommed = true;
        }
      });
    },
    getUserChildren() {
      this.$axios({
        url: "user/familyMembers/queryListPage",
        params: {
          user_id: this.userInfo.user_id,
        },
      }).then((res) => {
        this.childrenData = res.data.data;
      });
    },
    //佣金
    getUserPrice() {
      this.$axios({
        url: "user/userRecommenderIncomeLog/queryListPageAll",
        params: {
          currentPage: this.currentPage,
          pageSize: 10,
          recommender_id: this.userInfo.user_id,
        },
      }).then((res) => {
        this.tableData = res.data.data;
        if (res.data.data.length >= 10) {
          this.total = this.currentPage * 10 + 1;
        } else {
          this.total = this.currentPage * 10;
        }
      });
    },
    searchCoupon() {
      this.currentPage = 1;
      this.getUserCoup();
    },
    //券列表
    getUserCoup() {
      this.coupon_status = this.coupon_type;
      this.$axios({
        url: "user/userCoupon/queryManagerListPage",
        params: {
          currentPage: this.currentPage,
          pageSize: 10,
          user_id: this.userInfo.user_id,
          status: this.coupon_type,
        },
      }).then((res) => {
        this.tableData = res.data.data.rows;
        this.total = res.data.data.total;
        if (this.coupon_type == 1) {
          this.couptotal = res.data.data.total;
        }
      });
    },
    getcard() {
      this.$axios({
        url: "user/userMember/queryListPage",
        params: {
          is_teach_paypal: true,
          is_member: true,
          user_id: this.userInfo.user_id,
        },
      }).then((res) => {
        this.cardList = res.data.data;
        for (let i = 0; i < res.data.data.length; i++) {
          if (this.cardList[i].type == "优学卡") {
            this.cardInfo = this.cardList[i];
            return;
          }
        }
      });
    },
    completeCourse(row) {
      this.$confirm(
        `确认结束${row.map.masterSetPriceEntity.title}吗，剩余${row.course_num}课时`
      ).then((res) => {
        this.$axios({
          url: "user/userStudyCard/update",
          data: {
            id: row.id,
            status: 3,
            course_num: 0,
          },
          method: "post",
        }).then((res) => {
          if (res.data.code == 0) {
            this.$message("课程已结束");
            this.getCourseList();
          }
        });
      });
    },
    // 搜索点击
    searchclick() {},
    toCheck() {
      this.$router.push({ name: "TimeManage" });
    },
    //修改时间
    subTime() {
      if (this.value1 == this.value2) {
        this.$message({ message: "结束时间不能小于开始时间" });
        return;
      }
      if (!this.date || !this.value1 || !this.value2) {
        this.$message({ message: "请选择时间" });
        return;
      }
      let url = "user/masterNotice/insert";
      let data = {
        appointment_id: this.curCourse.id,
        type: "换课",
        change_starttime: this.date + " " + this.value1,
        change_endtime: this.date + " " + this.value2,
        remark:this.remark
      };
      this.$axios({ url, data, method: "post" }).then((res) => {
        this.$message({ message: res.data.message });
        this.timeshow = false;
      });
    },
    isdeadline(time) {
      let now = new Date().getTime();
      let temp = new Date(time).getTime();
      if (now > temp) {
        return false;
      } else {
        return true;
      }
    },
    timeCheck() {
      let now = new Date(this.start_time).getTime();
      let temp = new Date(this.end_time).getTime();
      if (now > temp) {
        return false;
      } else {
        return true;
      }
    },
    updateTime(row) {
      this.timeshow = true;
      this.curCourse = row;
    },
    //核销
    toHexiao(row) {
      let url = "user/userAppointment/checkAttendClass";
      let data = {
        id: row.id,
        mechanism_id: row.mechanism_id,
      };
      if (row.master_type.includes("need")) {
        data = {
          id: row.id,
          coach_id: row.coach_id,
          course_type: "need",
        };
      }
      this.$axios({ url, data, method: "post" }).then((res) => {
        this.$message({ message: res.data.message });
        this.GetAppointDetail();
      });
    },

    //预约课程详情
    GetAppointDetail() {
      let data = {
        currentPage: this.a_page,
        pageSize: 10,
        offset: 8,
        status: this.appstatus.status,
        mobile: this.userInfo.mobile,
      };
      if (this.openMode != "all") {
        data.study_card_id = this.curCourse.id;
      }
      let url = "/user/userAppointment/list/page";

      if (this.appstatus.status == 2) {
        data.attend_class_start_time =
          new Date().Format("yyyy-MM-dd") + " 00:00:00";
        data.attend_class_end_time =
          new Date(new Date().setDate(31)).Format("yyyy-MM-dd") + " 23:59:59";
      }
      this.$axios({ url, params: data, method: "get" }).then((res) => {
        this.appointmenting = false;
        this.appointCourse = res.data.data.rows;
        this.a_total = res.data.data.total;
      });
    },
    getNoStartCourse() {
      let url = "/user/userAppointment/queryOfflineSchedule";
      let data = {
        start_time: new Date().Format("yyyy-MM-dd") + " 00:00:00",
        is_teach_paypal: true,
        currentPage: this.currentPage,
        pageSize: 10,
        offset: 8,
        user_id: this.$route.params.id,
        // categories: "体育运动",
        status: 2,
        // master_type: "mechanism_offline",
      };
      this.$axios({ url, params: data, method: "get" }).then((res) => {
        this.NoStartCourse = res.data.data;
        if (res.data.data.length >= 10) {
          this.ctotal = res.data.data.length + 10;
        } else {
          this.ctotal = res.data.data.length;
        }
      });
    },
    showAppotion(mode, row) {
      this.openMode = mode;
      this.curCourse = row;
      this.appointShow = true;
      this.GetAppointDetail();
    },

    appointCoursePage(v) {
      this.a_page = v;
      this.GetAppointDetail();
    },
    handleChange1() {
      this.searchappoint1();
    },
    searchappoint() {
      console.log(this.appstatus);
      this.a_page = 1;
      this.appointCourse = [];
      this.appointmenting = true;
      this.GetAppointDetail();
    },
    search() {
      this.currentPageChange(1);
    },

    getStatus(v) {
      this.status = v.status;
      this.finished = v.finished;
    },
    getUserInfo() {
      let url = "/user/userInfo/findById";
      return this.$axios
        .get(url, {
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          this.userInfo = res.data.data;
           this.getChargeList();
      this.queryCardList();
      this.getUserCoup();
      this.getUserChildren();
      this.UserIsRecommed();
        });
    },
    getChargeList() {
      this.is_loading = true;
      let url = "/user/rechargerecord/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            is_teach_paypal: true,
            rcharge_type: this.rcharge_type ? this.rcharge_type : null,
            user_id: this.$route.params.id,
            pageSize: 10,
            // finished: true,
            currentPage: this.currentPage,
            status: this.status_group || null,
          },
        })
        .then((res) => {
          this.list = res.data.data.rows;
          this.total = res.data.data.total;
          this.is_loading = false;
        });
    },
    currentPageChange(v) {
      this.currentPage = v;
      switch (this.activeName) {
        case "first":
          this.getChargeList();
          break;
        case "second":
          this.getPointList();
          break;
        case "third":
          this.getCourseList();
          this.Coursevoucher();
          break;
        case "four":
          this.getNoStartCourse();
          break;
        case "five":
          this.getUserCoup();
          break;
        case "six":
          this.getUserPrice();
          break;
      }
    },
    handleClick() {
      this.flowing_no = null;
      this.rcharge_type = null;
      this.rcharge_time = [];
      this.status = null;
      this.list = [];
      this.currentPageChange(1);
    },

    getCourseList() {
      this.is_loading = true;
      let url = "/user/userStudyCard/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            currentPage: this.currentPage,
            pageSize: 10,
            user_id: this.$route.params.id,
          },
        })
        .then((res) => {
          this.list = res.data.data.rows;
          this.total = res.data.data.total;
          this.is_loading = false;
        });
    },
    getPointList() {
      this.is_loading = true;
      let url = "/user/userPointLog/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            user_id: this.$route.params.id,
            pageSize: 10,
            currentPage: this.currentPage,
          },
        })
        .then((res) => {
          this.list = res.data.data.rows;
          this.total = res.data.data.total;
          this.is_loading = false;
        });
    },
    handleClick2() {
      if (this.activeName2 == 1) {
        this.Coursevoucher();
      }
    },
    // 课程券
    Coursevoucher() {
      let url = "user/userCoupon/queryManagerListPage";
      let data = {
        user_id: this.$route.params.id,
        pageSize: 5,
        currentPage: 1,
        type: "天赋课程券",
        status: 1,
      };
      this.$axios.get(url, { params: data }).then((res) => {
        this.Couponlist = res.data.data.rows;
      });
    },
    //用户拥有卡列表
    queryCardList() {
      let url = "user/userMember/queryListPage";
      let params = {
        type: "优学卡",
        user_id: this.$route.params.id,
        pageSize: 20,
        currentPage: 1,
      };
      this.$axios({
        url,
        params,
      }).then((res) => {
        this.cardInfo = res.data.data[0] || {};
        this.cardList = res.data.data;
      });
    },
    //选择课程
    chooseCourse() {
      this.queryCardList();
      this.dialogTableVisible = true;
    },
    toCourse(row) {
      this.$router.push(
        "/main/mecCouList?id=" +
          this.$route.params.id +
          "&card_id=" +
          row.point_id +
          "&type=buy"
      );
    },
    couptoCourse(row) {
      this.$router.push(
        "/main/mecCouList?id=" +
          this.$route.params.id +
          "&coup_id=" +
          row.id +
          "&type=buy"
      );
    },
    beforeTime() {
      var date = new Date();
      var seperator1 = "-";
      var seperator2 = ":";
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      var hour = date.getHours();
      var minutes = date.getMinutes();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      let seconds = date.getSeconds();
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      var currentdate =
        date.getFullYear() +
        seperator1 +
        month +
        seperator1 +
        strDate +
        " " +
        hour +
        seperator2 +
        minutes +
        seperator2 +
        seconds;
      return currentdate;
    },
  },
};
</script>

<style scoped>
.cell {
  text-align: center;
}
span {
  cursor: pointer;
}
</style>
